import PropTypes from 'prop-types';
import React from 'react';
import ms from 'ms';

import { getCandidateChatTopics } from 'shared/functions';
import { useGenericContext } from 'shared/hooks';

import SelectJob from './SelectJob';

let mounted;
function MenuChat(props) {
  const {
    settings: { candidateChatTopics },
  } = useGenericContext();
  const { emitSendMessage, switchToChat, setCentralContent, mobileMode, pipelines, loadingPipelines } = props;

  const [showJobs, setShowJobs] = React.useState(false);
  const [topicsDisabled, setTopicsDisabled] = React.useState(false);

  const [chatTopics] = React.useState(getCandidateChatTopics(candidateChatTopics));

  React.useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  if (mobileMode) {
    return null;
  }
  if (showJobs) {
    return (
      <SelectJob
        hideJobs={() => setShowJobs(false)}
        emitSendMessage={emitSendMessage}
        switchToChat={switchToChat}
        setCentralContent={setCentralContent}
        pipelines={pipelines}
        loadingPipelines={loadingPipelines}
      />
    );
  }

  return (
    <div className="text-center candidate-chat-menu">
      <h1
        style={{
          marginTop: '50px',
        }}
      >
        {translate('Chat topics')}
      </h1>
      <div className="intro margin-bottom">{translate('Select a chat bubble below to quickly change the topic')}</div>
      <div className="clearfix" />
      <ul className="select-messages">
        {chatTopics.map(({ topic, selectJob }) => (
          <li
            className="margin-bottom"
            key={topic}
            style={
              topicsDisabled
                ? {
                    opacity: 0.5,
                  }
                : {}
            }
            onClick={() => {
              if (topicsDisabled) {
                return;
              }
              setTopicsDisabled(true);
              setTimeout(() => {
                if (!mounted) {
                  return;
                }
                setTopicsDisabled(false);
              }, ms('5s'));
              if (selectJob) {
                return setShowJobs(true);
              }

              emitSendMessage(topic, null, null);
            }}
          >
            <div className="bubble me">
              <div className="bubble-content">
                <span className="message-content">{topic}</span>
              </div>
            </div>
            <div className="clearfix" />
          </li>
        ))}
      </ul>
    </div>
  );
}

MenuChat.contextTypes = {
  settings: PropTypes.object.isRequired,
};

MenuChat.propTypes = {
  mobileMode: PropTypes.bool.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  switchToChat: PropTypes.func.isRequired,
  setCentralContent: PropTypes.func.isRequired,
  pipelines: PropTypes.array.isRequired,
  loadingPipelines: PropTypes.bool.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default MenuChat;
