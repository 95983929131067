import { requestBackend } from '@eva/emf/app/utils/request';
import type { SkillTaxonomyTreeDto, SkillTaxonomyDto } from '@eva/types/dto';

type FetchSkillTaxonomyTrees = (data: { code: string }) => Promise<SkillTaxonomyTreeDto[]>;

export const fetchSkillTaxonomyTrees: FetchSkillTaxonomyTrees = ({ code }) =>
  requestBackend(`/public-jobs/skill-taxonomies/trees/${code}`, {
    method: 'POST',
  });

type fetchSkillTaxonomyOptionsType = () => Promise<{
  skillTaxonomies: SkillTaxonomyDto[];
}>;

type FetchSkillTaxonomyTree = (data: {
  skillTaxonomyId: number;
  rootGroupId?: string | null;
}) => Promise<SkillTaxonomyTreeDto>;

export const fetchSkillTaxonomiesOptions: fetchSkillTaxonomyOptionsType = () =>
  requestBackend('/skill-taxonomies/my/candidate/options');

export const fetchSkillTaxonomyTree: FetchSkillTaxonomyTree = ({ skillTaxonomyId, rootGroupId }) =>
  requestBackend(`/skill-taxonomies/my/candidate/tree/${skillTaxonomyId}`, {
    method: 'POST',
    body: JSON.stringify({
      rootGroupId,
    }),
  });
