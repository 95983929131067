import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import every from 'lodash/every';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { prepareSelector } from 'shared/functions';
import { useGenericContext } from 'shared/hooks';

import PreviewHeader from 'containers/CandidateProfile/PreviewHeader';

import AvailabilityWidget from './AvailabilityWidget';
import StaticPagesWidget from './StaticPagesWidget';

const MenuHome = (props: any) => {
  const { settings, isAllowedOperation } = useGenericContext();

  const { mobileMode, setCentralContent, loadingUserProfile, userProfile } = props;

  if (loadingUserProfile) {
    return (
      <div className="text-center padding">
        <Spinner />
      </div>
    );
  }

  const { features, staticPages } = settings;

  const isAvailabilityWidgetAllowed = every([
    isAllowedOperation('frontend-availabilityWidget-view'),
    isAllowedOperation('myProfile-availability-view'),
  ]);

  return (
    <div className="text-center candidate-home-menu">
      <PreviewHeader entity={userProfile} readOnly />

      {isAvailabilityWidgetAllowed && <AvailabilityWidget entity={userProfile} />}

      {isAllowedOperation('startPages-viewAllowed') && features?.microservices?.staticPages && (
        <StaticPagesWidget setCentralContent={setCentralContent} staticPages={staticPages} mobileMode={mobileMode} />
      )}
    </div>
  );
};

MenuHome.propTypes = {
  userProfile: PropTypes.object.isRequired,
  loadingUserProfile: PropTypes.bool.isRequired,
  setCentralContent: PropTypes.func,
  mobileMode: PropTypes.bool.isRequired,
};

const prepareGlobalSelector = (value: any) => prepareSelector('global', value);
const mapStateToProps = createStructuredSelector({
  userProfile: prepareGlobalSelector('userProfile'),
  loadingUserProfile: prepareGlobalSelector('loadingUserProfile'),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(MenuHome);
