import PropTypes from 'prop-types';
import React from 'react';
import Color from 'color';

const TimeCell = ({ dayNum, rowNum, bgColor, borderColor, timeInterval, dragging }) => {
  const gapColor = borderColor.includes('repeating-linear-gradient');
  let background = bgColor;
  if (dragging) {
    background = gapColor ? 'gray' : Color(borderColor).darken(0.2);
  }
  return (
    <td
      className="quarter-cell"
      data-day={dayNum}
      data-row={rowNum}
      style={{
        background,
        borderBottomColor: borderColor && !gapColor ? (Color(borderColor).lighten(0.1) as any) : '',
        color: gapColor ? 'gray' : 'white',
        fontSize: '12px',
      }}
    >
      <div data-day={dayNum} data-row={rowNum} className="time-cell">
        {timeInterval}
      </div>
    </td>
  );
};

TimeCell.propTypes = {
  dayNum: PropTypes.number.isRequired,
  rowNum: PropTypes.number.isRequired,
  bgColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  timeInterval: PropTypes.string.isRequired,
  dragging: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default TimeCell;
