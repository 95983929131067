import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';

import type { FileKindConfig } from '@eva/emf/app/containers/FilesTable';
import { FileKindTable, FilesFilter, getFileUploadInitialValues } from '@eva/emf/app/containers/FilesTable';
import type { AttachmentTypeDto, BaseAttachmentDto } from '@eva/types/dto';
import ModalUploadCv from '@eva/emf/app/shared/ui/Modal/ModalUploadCv';
import ModalUploadFile from '@eva/emf/app/shared/ui/Modal/ModalUploadFile';
import { useGenericContext } from 'shared/hooks';
import { getUserGroupIds } from 'shared/functions/user';

import NoFile from 'assets/images/no_file.svg';
import NoCv from 'assets/images/no_cv.svg';

import type { CardCvAndAttachmentsPropsType } from './types';

import {
  createCandidateFile,
  createResume,
  deleteCandidateFile,
  deleteResume,
  parseResume,
  signCandidateFileUrl,
  updateCandidateFile,
  signCandidateResumeUrl,
} from './services';

const getFileKinds = () => [
  {
    plain: true,
    entityPath: 'cvs',
    fileLabel: translate('My CV'),
    fileIdKey: 'resumeId',
    fileIcon: 'fa fa-file-text-o',
    imageNoFiles: NoCv,
    fileClassName: 'cv',
    operations: {
      view: {
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-cvs-view'),
      },
      parse: {
        action: parseResume,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-cvs-parse'),
      },
      create: {
        action: createResume,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-cvs-create'),
      },
      delete: {
        action: deleteResume,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-cvs-delete'),
      },
      obtainUrl: {
        action: signCandidateResumeUrl,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-cvs-view'),
      },
    },
    UploadComponent: ModalUploadCv,
    getUploadComponentOptions: (_props: any, { isAllowedOperation }) => ({
      canParse: isAllowedOperation('myProfile-cvs-parse'),
      fileTypeIdKey: 'resumeId',
      intialValues: {},
    }),
  },
  {
    entityPath: 'attachments',
    fileLabel: translate('My file'),
    fileIcon: 'lnr lnr-paperclip',
    imageNoFiles: NoFile,
    fileClassName: 'my-files',
    fileIdKey: 'attachmentId',
    fileTypeIdKey: 'attachmentTypeId',
    getFileTypes: (editOptions: any) => editOptions.attachmentTypes,
    getUploadComponentOptions: ({ fileTypes }: { fileTypes: AttachmentTypeDto[] }) => ({
      fileTypes,
      fileTypeIdKey: 'attachmentTypeId',
      canVerify: false,
      initialValues: getFileUploadInitialValues(fileTypes),
    }),
    UploadComponent: ModalUploadFile,
    operations: {
      view: {
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-attachments-view'),
      },
      create: {
        action: createCandidateFile,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-attachments-create'),
      },
      update: {
        action: updateCandidateFile,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-attachments-update'),
      },
      delete: {
        action: deleteCandidateFile,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-attachments-delete'),
      },
      obtainUrl: {
        action: signCandidateFileUrl,
        isAllowed: (isAllowedOperation: any) => isAllowedOperation('myProfile-attachments-view'),
      },
    },
    FilterComponent: FilesFilter,
  },
];

const CardCvAndAttachments = <E extends { userId: number }, F extends BaseAttachmentDto>({
  entity,
  editOptions,
  loadEntity,
  updateEntity,
}: CardCvAndAttachmentsPropsType<E>) => {
  const [selectedFile, setSelectedFile] = useState<BaseAttachmentDto>();
  const fileKinds = useMemo(() => getFileKinds(), []);

  const { isAllowedOperation, settings } = useGenericContext();
  const userGroupIds = getUserGroupIds();
  const onFileUploaded = (file: F, fileType: FileKindConfig<F, any, any>) => {
    const existingFiles = entity[fileType.entityPath];
    // @ts-expect-error allow implicit here
    updateEntity({ [fileType.entityPath]: [...existingFiles, file] });
  };

  const onFileUpdated = useCallback(
    (file: F, fileType: FileKindConfig<F, any, any>, forceReload: boolean) => {
      if (forceReload && typeof loadEntity === 'function') {
        loadEntity();
      }
      const existingFiles = entity[fileType.entityPath];
      const attachmentIndex = existingFiles.findIndex(
        (existingFile: any) => existingFile[fileType.fileIdKey] === file[fileType.fileIdKey],
      );

      if (attachmentIndex > -1) {
        existingFiles[attachmentIndex] = file;
        // @ts-expect-error allow implicit here
        updateEntity({ [fileType.entityPath]: [...existingFiles] });
      }
    },
    [entity, loadEntity, updateEntity],
  );

  const onFileDeleted = (file: F, fileType: FileKindConfig<F, any, any>) => {
    const existingFiles = entity[fileType.entityPath];

    const filesWithoutDeleted = existingFiles.filter(
      (existingFile: any) => existingFile[fileType.fileIdKey] !== file[fileType.fileIdKey],
    );

    // @ts-expect-error allow implicit here
    updateEntity({ [fileType.entityPath]: filesWithoutDeleted });
  };

  const onFileSelected = useCallback(
    (selectedFile) => {
      setSelectedFile(selectedFile);
    },
    [setSelectedFile],
  );

  return (
    <div className="preview-panel">
      {fileKinds.map((fileKind) => {
        if (!fileKind.operations) {
          return null;
        }

        if (!fileKind.operations.view.isAllowed(isAllowedOperation)) {
          return null;
        }

        const files = entity[fileKind.entityPath];
        const fileTypes = fileKind.getFileTypes?.(editOptions) || [];

        return (
          <FileKindTable
            isAllowedOperation={isAllowedOperation}
            settings={settings}
            userGroupIds={userGroupIds}
            key={fileKind.entityPath}
            files={files}
            fileKindConfig={fileKind}
            plain={fileKind.plain}
            fileTypes={fileTypes}
            entityId={entity.userId}
            onFileUploaded={onFileUploaded}
            onFileUpdated={onFileUpdated}
            onFileDeleted={onFileDeleted}
            onFileSelected={onFileSelected}
            selectedFile={selectedFile}
          />
        );
      })}
    </div>
  );
};

CardCvAndAttachments.propTypes = {
  loadEntity: PropTypes.func,
  entity: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default memo(CardCvAndAttachments);
